.custom-button {
  position: relative;
  background: linear-gradient(to right, #e19fad, #f7edf7);
  color: black;
  font-family: 'Britney-Regular';
  padding: 15px 20px;
  border: 1px solid #e19fad;
  border-radius: 50px;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(to right, #f7edf7, #e19fad);
    border-radius: 25px;
    z-index: -1;
    transition: width 1.5s ease;
  }

  &:hover::before {
    width: 100%;
  }

  &:hover {
    color: black;
    transform: scale(1.06);
  }

  &:active {
    transform: scale(1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 1200px) {
  .custom-button {
    font-size: 18px;
  }
}
