.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 0;
  margin: 0 auto;
  z-index: 1;
  opacity: 1;
}
