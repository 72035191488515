$primary-color: #d8d8d8;

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');

@font-face {
  font-family: 'Britney-Regular';
  src: url('../src/assets/fonts/Britney/Britney-Regular.woff2') format('woff2'),
    url('./assets/fonts/Britney/Britney-Regular.woff') format('woff'),
    url('./assets/fonts/Britney/Britney-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Supreme-Regular';
  src: url('./assets/fonts/Supreme/Supreme-Regular.woff2') format('woff2'),
    url('./assets/fonts/Supreme/Supreme-Regular.woff') format('woff'),
    url('./assets/fonts/Supreme/Supreme-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
