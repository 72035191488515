.modal-content-presta {
  position: relative;
  max-width: 95%;
  max-height: 95%;
  background-color: #fff;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  border-radius: 35px;
}

.modal-close-presta {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 4rem;
  cursor: pointer;
  z-index: 101;
  color: rgba(0, 0, 0, 0.8);
}

.modal-close {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 35px;
  position: absolute;
  right: 2%;
  top: 1%;
}

.carousel-image {
  max-width: 100%;
  max-height: 60vh;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 35px 35px 0 0;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 4rem;
  cursor: pointer;
  z-index: 102;
  color: #e19fad;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.supplement-details {
  text-align: center;
  margin-top: 0;
  color: black;

  h3 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
