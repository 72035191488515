.contact-page {
  overflow: hidden;
  height: 100vh;

  h1 {
    font-size: 9rem;
    font-weight: normal;
    margin-top: 0;
    position: relative;
    margin-bottom: 30px;
    left: -10px;
  }

  .text-zone {
    position: absolute;
    left: 12%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;

    .contact-button {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    .phone-number {
      cursor: pointer;
    }

    span {
      font-size: 20px;
      font-family: 'Britney-Regular';
    }

    .contact-icon {
      color: black;
      font-size: 22px;
      margin-right: 10px;
    }
  }

  .map-wrap {
    float: right;
    width: 52%;
    height: 100vh;
  }

  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 0.1s;
    animation-fill-mode: forwards;
  }

  .info-map {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    top: 19%;
    right: 32%;
    z-index: 999999;
    width: 260px;
    padding: 20px;
    color: #fff;
    font-family: 'Supreme-Regular', sans-serif;
    font-size: 12px;
    line-height: 1.8;
    font-weight: 400;
    opacity: 0;
    animation: fadeIn 1s 1.1s;
    animation-fill-mode: forwards;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    padding: 5px;
  }
}

@keyframes backInRight {
  0% {
    opacity: 0;
    transform: translateX(1000px) scale(0.7);
  }
  80% {
    opacity: 0.6;
    transform: translateX(-10px) scale(0.7);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100vh;
    overflow: visible;
    padding-top: 0;

    h1 {
      font-size: 7rem;
    }

    .text-zone {
      position: static;
      width: 80%;
      margin-bottom: 20px;
      transform: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .map-wrap {
      float: none;
      width: 70%;
      height: 500px;
      margin-bottom: 0;
    }

    .info-map {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .contact-page {
    padding-top: 0;
    margin-top: 0;

    h1 {
      font-size: 5.5rem;
    }

    .text-zone {
      width: 90%;

      span {
        font-size: 16px;
      }

      .contact-icon {
        font-size: 18px;
      }
    }

    .map-wrap {
      width: 80%;
      height: 450px;
      margin-bottom: 0;
    }

    .info-map {
      display: none;
    }
  }
}
