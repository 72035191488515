.prestation-container {
  font-family: 'Supreme-Regular', sans-serif;
  padding-left: 0;
  top: 0;
  margin-left: 107px;
  width: 93%;
  overflow: visible;
}

.category {
  margin-bottom: 50px;
}

.category-title {
  font-size: 6rem;
  font-weight: normal;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.button-supplement-container {
  margin-top: 160px;
  margin-left: 60px;
}

.category-card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-right: 10px;
  align-items: start;
  overflow: visible;
}

.card {
  overflow: visible;
  background-color: #f7edf7;
  border-radius: 0 35px 0 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  overflow: visible;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    display: block;
  }

  .card-content {
    position: relative;
    background: #f7edf7;
    color: #000;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    padding: 10px;
    border-radius: 0;
    grid-auto-rows: min-content;
    display: block;
    padding-bottom: 30px;

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }

  .card.expanded .expand-icon {
    transform: translateX(-50%);
  }

  .expand-icon {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    color: #e19fad;
    background: #f7edf7;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .expand-icon:hover {
    transform: translateX(-50%) scale(1.1);
  }

  &.expanded {
    .expand-icon {
      transform: translateX(-50%) rotate(45deg);
    }

    .card-description {
      display: block;
      padding: 10px;
      font-size: 16px;
      color: #666;
    }
  }

  .placeholder-image {
    width: 100%;
    height: 200px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    font-size: 14px;
  }

  .content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
  }

  .details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 2px 0;
    }
  }

  .title-details {
    font-weight: bold;
  }

  .reservation-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }

  .reservation-button {
    font-size: 20px;
    padding: 5px 20px;
    background: transparent;
    border: 1px solid #e19fad;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0);
    align-self: flex-end;
  }
}

@media screen and (max-width: 1200px) {
  .prestation-container {
    width: 92%;
    margin-left: 50px;
  }

  .category-card {
    grid-template-columns: repeat(3, 1fr);
  }

  .category-title {
    font-size: 5.6rem;
  }

  .card {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .card-image {
      height: 160px;
    }

    .card-content {
      position: static;
      background: #f7edf7;
      color: #000;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      padding: 10px;
      border-radius: 0 0 35px 0;
      display: block;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .category-card {
    grid-template-columns: repeat(2, 1fr);
  }

  .card:last-child {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  .prestation-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .category-card {
    grid-template-columns: 1fr;
    justify-items: center;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    width: 100%;
  }

  .category-title {
    font-size: 5rem;
    text-align: center;
  }

  .card {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;

    .card-image {
      height: 160px;
    }

    .card-content {
      position: static;
      background: #f7edf7;
      color: #000;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      padding: 10px;
      border-radius: 0 0 35px 0;
      display: block;

      h2 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .card:last-child {
    margin-bottom: 80px;
  }

  .button-supplement-container {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .reservation-button-container span {
    font-size: 18px;
  }
}
