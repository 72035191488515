.about-page {
  h1 {
    font-size: 5.8rem;
    font-weight: normal;
    margin-top: 0;
    position: relative;
    margin-bottom: 30px;
    left: -10px;
    text-align: Left;
  }

  h2 {
    font-size: 22px;
  }

  p {
    font-size: 16px;
  }

  ul {
    font-size: 16px;
    padding-left: 0;
    list-style-type: circle;
  }

  .about-button {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 30px;
    gap: 15px;
    margin-left: 32%;
  }

  .text-zone {
    position: absolute;
    left: 12%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;

    .about-text {
      font-size: 16px;
      line-height: 1.6;
      font-weight: 500;
      margin-bottom: 20px;
    }

    span {
      font-family: 'Britney-Regular';
    }
  }

  .slider-zone {
    position: absolute;
    right: 7%;
    top: 45%;
    transform: translateY(-50%);
    width: 35%;
    height: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .about-page {
    h1 {
      font-size: 4.3rem;
      text-align: center;
    }

    .text-zone {
      .about-text {
        font-size: 14px;
      }

      p,
      ul {
        font-size: 14px;
      }

      h2 {
        font-size: 20px;
      }

      .about-button {
        margin-left: 0;
      }
    }
    .slider-zone {
      height: 500px;
    }
  }
}

@media screen and (max-width: 767px) {
  .about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    overflow-y: auto;
    padding: 20px 0;
    text-align: left;

    h1 {
      font-size: 4rem;
      margin-bottom: 15px;
    }

    .text-zone {
      position: static;
      width: 90%;
      max-width: 500px;
      padding: 0 10px;
      margin: 10px auto;
      transform: none;

      .about-text {
        font-size: 14px;
        line-height: 1.4;
      }
      .about-button {
        justify-content: center;
      }

      margin-bottom: 15px;
    }

    .slider-zone {
      position: relative;
      width: 75%;
      order: 1;
      margin-bottom: 0;
      padding-bottom: 0;
      height: 400px;
      margin-left: 15%;
      margin-top: 260px;
    }
  }
}
