.home-page {
  h1 {
    font-size: 8rem;
    font-weight: normal;
    margin-top: 70px;
    position: relative;
    margin-bottom: 0;
    left: -10px;
  }

  .city-h1 {
    font-size: 3rem;
    font-family: 'Supreme-Regular', sans-serif;
  }

  .text-zone {
    position: absolute;
    left: 12%;
    top: 50%;
    transform: translateY(-50%);
    width: 45%;

    .home-button {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      margin-top: 30px;
      gap: 5px;
      justify-content: center;

      .home-icon {
        margin-right: 10px;
      }
    }
  }

  .image-zone {
    position: absolute;
    right: 12%;
    top: 55%;
    transform: translateY(-50%);
    width: 24%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      border-radius: 500px 500px 0 0;
      box-shadow: 0 4px 20px rgb(0, 0, 0, 0.7);
      width: 100%;
    }

    .about-name {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
      font-family: 'Great vibes';
      text-align: center;
      align-items: center;
      padding-left: 0;
    }

    .about-subtext {
      font-size: 14px;
      text-align: center;
      padding-left: 0;
    }
  }

  .home-text {
    margin-top: 40px;
  }

  p {
    font-size: 18px;
  }

  .button-container {
    display: flex;
    gap: 50px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    min-height: 100vh;
    padding: 0;
    box-sizing: border-box;

    h1 {
      font-size: 6rem;
      margin-top: 10px;
      left: 0;
      width: 100%;
      text-align: center;
    }

    .city-h1 {
      font-size: 2.5rem;
    }

    .text-zone {
      position: static;
      width: 100%;
      max-width: 500px;
      transform: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 70px;

      .home-button {
        justify-content: start;
      }
    }

    .home-text {
      margin-top: 20px;
      order: 3;
    }

    .button-container {
      margin-top: 0;
      order: 1;
    }

    .image-zone {
      position: static;
      width: 33%;
      margin-top: 27%;
    }

    .about-subtext {
      margin-bottom: 100px;
    }
  }
}

@media screen and (max-width: 767px) {
  .home-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 4rem;
      text-align: center;
      left: 0;
      width: 100%;
    }

    .city-h1 {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 0;
    }

    .image-zone {
      margin-top: 40%;
      width: 50%;
    }

    .button-container {
      order: 1;
      gap: 5px;
      margin-top: 0;
      margin-bottom: 0;
    }

    .text-zone {
      margin-bottom: 30px;
      padding: 20px;
      max-width: 90%;
    }

    .home-text {
      order: 3;
    }

    .image-zone {
      width: 50%;
      margin-top: 48%;
    }

    .home-icon {
      font-size: 17px;
      margin: 0;
    }

    .home-button {
      font-size: 15px;
      justify-content: start;
    }
  }
}

@media screen and (max-width: 375px) {
  .home-page {
    h1 {
      font-size: 3.5rem;
      text-align: center;
      left: 0;
      width: 100%;
    }

    .city-h1 {
      font-size: 1.8rem;
      text-align: center;
    }

    .text-zone {
      margin-bottom: 15px;
    }

    .button-container {
      gap: 5px;
    }

    .home-button {
      font-size: 12px;
      padding: 5px;
      margin: 0;
      justify-content: start;
    }

    .home-icon {
      font-size: 17px;
      margin: 0;
    }

    .image-zone {
      width: 70%;
      max-width: 180px;
      margin-top: 200px;
    }
  }
}
