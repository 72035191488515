.reviews-page {
  width: 80%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  h1 {
    font-size: 8rem;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 30%;
  }

  .reviews-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    flex-grow: 1;
  }

  .review-card {
    background: white;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    display: flex;
    flex-direction: column;
  }

  .review-header {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .review-avatar {
    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
    }

    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: white;
    margin-right: 15px;
    flex-shrink: 0;
  }

  .review-author {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
  }

  .review-author h2 {
    margin: 0;
    font-size: 14px;
  }

  .review-rating {
    color: #ffb400;
    font-size: 14px;
  }

  .review-text {
    margin-top: 5px;
    font-size: 14px;
  }

  .add-review {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1% auto 20px;
    width: auto;
  }
}

@media screen and (max-width: 1200px) {
  .reviews-page {
    width: 90%;
    padding-bottom: 70px;

    h1 {
      text-align: center;
      margin-left: 0;
      font-size: 7rem;
    }

    .review-card {
      max-width: 100%;
    }

    .add-review {
      position: static;
      margin-top: 30px;
      margin-bottom: 70px;
    }
  }
}

@media screen and (max-width: 767px) {
  .reviews-page {
    width: 90%;
    padding-bottom: 70px;

    h1 {
      text-align: center;
      font-size: 5rem;
    }

    .review-card {
      width: 100%;
    }

    .add-review {
      width: 90%;
      margin-top: 20px;
      margin-bottom: 60px;
    }
  }
}
