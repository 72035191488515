.slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  overflow: hidden;
}

.image-container {
  position: absolute;
  width: 100%;
  height: 550px;
  overflow: hidden;
  border-radius: 45px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-before {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(
    0 0,
    var(--slider-value) 0,
    var(--slider-value) 100%,
    0% 100%
  );
  z-index: 5;
}

.slider-after {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(
    var(--slider-value) 0,
    100% 0,
    100% 100%,
    var(--slider-value) 100%
  );
  z-index: 4;
}

.slider-separator {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  left: var(--slider-value);
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: none;
}

.slider-line {
  width: 2px;
  height: 100%;
  background-color: #f1f1f1;
  left: 50%;
  pointer-events: none;
}

.slider-guide-bar-back {
  position: fixed;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85px;
  height: 2px;
  background-color: #e19fad;
  z-index: 100;
}

.slider-guide-bar {
  position: fixed;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  border-radius: 30px;
  height: 7px;
  background-color: #e19fad;
  animation: scroll-animation 4s infinite;
}

@keyframes scroll-animation {
  0% {
    transform: translate(-50%, -50%) translateX(-30px);
    animation-timing-function: ease-out;
  }
  65% {
    transform: translate(-50%, -50%) translateX(30px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate(-50%, -50%) translateX(-30px);
  }
}

.slider-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e19fad;
  border: 1px solid #111;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: transform 0.1s ease-out;
}

.slider-range {
  position: absolute;
  width: 100%;
  height: 100%;
  appearance: none;
  background: none;
  outline: none;
  cursor: pointer;
  z-index: 30;
  border: none;
}

.slider-range::-webkit-slider-thumb {
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  z-index: 30;
  transform: translateX(1px);
}

.slider-range::-moz-range-thumb {
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  z-index: 30;
  transform: translateX(1px);
}

.label {
  position: absolute;
  top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #f1f1f1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.before-label {
  left: 20px;
}

.after-label {
  right: 20px;
}

@media screen and (max-width: 767px) {
  .slider {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .slider-guide-bar-back {
    top: -15px;
  }
  .slider-guide-bar {
    top: -15px;
  }
}

@media screen and (max-width: 1200px) {
  .slider-circle {
    width: 30px;
    height: 30px;
    transition: transform 0.05s ease-out;
  }

  .slider-range::-webkit-slider-thumb {
    width: 30px;
    height: 30px;
    transform: translateX(1px);
  }

  .slider-range::-moz-range-thumb {
    width: 30px;
    height: 30px;
    transform: translateX(1px);
  }
}
