html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Supreme-Regular', sans-serif;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7edf7;
  overflow: visible;
  display: block;
}

h1,
h2,
h3,
span {
  font-family: 'Britney-Regular';
}

p,
ul {
  font-family: 'Supreme-Regular', sans-serif;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}
